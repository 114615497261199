<!--
  Copyright 2024 Dataport. All rights reserved. Developed as part of the MERLOT project.

  Licensed under the Apache License, Version 2.0 (the "License");
  you may not use this file except in compliance with the License.
  You may obtain a copy of the License at

      http://www.apache.org/licenses/LICENSE-2.0

  Unless required by applicable law or agreed to in writing, software
  distributed under the License is distributed on an "AS IS" BASIS,
  WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
  See the License for the specific language governing permissions and
  limitations under the License.
-->

<form (ngSubmit)="onSubmit()" *ngIf="showForm">
  <label for="firstName">First Name:</label>
  <input type="text" id="firstName" name="firstName" placeholder="Enter your first name" [(ngModel)]="firstName"
    required>

  <label for="lastName">Last Name:</label>
  <input type="text" id="lastName" name="lastName" placeholder="Enter your last name" required [(ngModel)]="lastName">

  <label for="email">E-Mail:</label>
  <input type="text" id="email" name="email" placeholder="user@organization.com" required [(ngModel)]="email"
    required="">
  
  <label for="roleType">Role Type</label>
  <select id="roleType" name="roleType" [(ngModel)]="roleType">
    <option value="OrgLegRep">Legal Representative</option>
    <option value="FedAdmin">Federation Administrator</option>
  </select>

  <label for="role">Organization</label>
  <select id="role" name="role" [(ngModel)]="organization">
    <option *ngFor="let org of organizations" [value]="org">{{ org }}</option>
  </select>

  <label for="subjectDid">Your DID: (do not change for testing)</label>
  <input type="text" id="subjectDid" name="subjectDid" placeholder="Enter your DID" [(ngModel)]="subjectDid">

  <button type="submit">Submit</button>
</form>

<div *ngIf="connectionId && connectionStatus !== 'trusted' && !showForm">
  <div class="qrcodeImage">
    <qrcode 
      [cssClass]="'demoBorder'"
      [margin]="4"
      [qrdata]="invitationUrl" 
      [width]="qrcodeDimension" 
      [errorCorrectionLevel]="'M'"
      [imageSrc]="'./assets/merlot-pcm-opaque.png'"
      [imageHeight]="150 * (qrcodeDimension/512)"
      [imageWidth]="150 * (qrcodeDimension/512)"
      >
    </qrcode>
  </div>
  Scan this code to connect.

  Connection status: {{connectionStatus}}
</div>

<div *ngIf="connectionStatus === 'trusted' && credentialId === ''">
  Connection sucessfull, your credential has been requested.
</div>

<div *ngIf="credentialId">
  Connection sucessfull, your credential has been requested.<br>
  Credential state: {{credentialState}}
</div>
